// App Color Code : #FDCE48
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import VueElementLoading from 'vue-element-loading'
import ServerError from './components/Common/500'
import ImageViewer from './components/Common/imageViewer'
import VCalendar from 'v-calendar';
import { VueEditor } from "vue2-editor";
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.component(VueCropper);
Vue.component('VueEditor', VueEditor)
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('ServerError', ServerError)
Vue.component('ImageViewer', ImageViewer)
import JsonExcel from "vue-json-excel";
Vue.use(VCalendar)
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false

window.axios = require('axios')


// axios.defaults.baseURL = 'http://192.168.49.195:4001';
// Vue.prototype.baseURL = "http://192.168.49.195:4001"
// Vue.prototype.mediaURL = "http://192.168.52.247:4001/u/"



// axios.defaults.baseURL = 'http://52.66.213.213';
// Vue.prototype.baseURL = "http://52.66.213.213"
// Vue.prototype.mediaURL = "http://52.66.213.213/file/get/"


// axios.defaults.baseURL = 'http://13.126.157.44';
// Vue.prototype.mediaURL = "http://13.126.157.44/wp/"

axios.defaults.baseURL = 'https://api.mudumalaitigerreserve.com';
Vue.prototype.baseURL = "https://api.mudumalaitigerreserve.com"
Vue.prototype.mediaURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/";

Vue.mixin({
    data() {
        return {
            webpSupported: true,
        };
    },

    created() {
        (async() => {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
                this.webpSupported = false;
                return;
            }
            // Base64 representation of a white point image
            const webpData =
                "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then((r) => r.blob());
            // If the createImageBitmap method succeeds, return true, otherwise false
            this.webpSupported = await createImageBitmap(blob).then(
                () => true,
                () => false
            );
            if (this.webpSupported) {
                Vue.prototype.mediaURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/";
            } else {
                Vue.prototype.mediaURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/";
            }
        })();
    },
});
new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')